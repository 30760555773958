<script lang="ts" setup>
import { useField } from 'vee-validate'

import { UFormGroup, UInput } from '#components'

export type InputType = InstanceType<typeof UInput>['$props'] & { [key: string]: unknown }
export type FormGroupType = /* @vue-ignore */ InstanceType<typeof UFormGroup>['$props']

const props = defineProps<
  FormGroupType & {
    name: string
    input?: InputType
  }
>()

const { name, input, ...formGroup } = toRefs(props)
const formGroupProps = reactive(formGroup)

const { value, errorMessage } = useField<string | number>(() => props.name)
</script>

<template>
  <UFormGroup v-bind="formGroupProps" :name="name" :error="errorMessage">
    <UInput v-bind="input" v-model="value" :name="name" />
  </UFormGroup>
</template>
